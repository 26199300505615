import { Box, InputLabel, MenuItem, FormControl, Select } from '@mui/material'
import PropTypes from 'prop-types'

export default function SelectBox({ label, currentValue, setCurrentValue, options }) {
  if (label && currentValue && setCurrentValue && options) {
    return (
      <Box sx={{ width: 200 }}>
        <FormControl fullWidth>
          <InputLabel>{label}</InputLabel>
          <Select
            MenuProps={{ PaperProps: { sx: { maxHeight: 350 } } }}
            value={currentValue}
            label={label}
            onChange={(event) => setCurrentValue(event.target.value)}
          >
            {options.map((option) => (
              <MenuItem key={option.id} value={option.id}>
                {option.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>
    )
  }
  return null
}

SelectBox.propTypes = {
  label: PropTypes.string,
  currentValue: PropTypes.string,
  setCurrentValue: PropTypes.func,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
    }).isRequired,
  ),
}

SelectBox.defaultProps = {
  label: undefined,
  currentValue: undefined,
  setCurrentValue: undefined,
  options: undefined,
}
