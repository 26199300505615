import { Chart } from 'react-google-charts'
import { useState, useEffect } from 'react'
import { CircularProgress, Stack } from '@mui/material'

import { ChartType } from '../common/consts'
import { fetchDataFromApi } from '../common/utils'
import { useApp } from '../AppContext'

export default function ChartBar() {
  const { indicator, indicatorId, groupId, sourceId, yearTo } = useApp()

  const [data, setData] = useState([])

  useEffect(() => {
    if (sourceId && indicatorId && groupId && yearTo) {
      fetchDataFromApi(`data/${ChartType.barChart}/${sourceId}/${indicatorId}/${groupId}/${yearTo}`, setData)
    }
  }, [sourceId, indicatorId, groupId, yearTo])

  // Todo: not working (chart do not refresh itself)
  // const [chartHeight, setChartHeight] = useState('1000px')
  // useEffect(() => {
  //   if (data.length !== 0) {
  //     setChartHeight(`${data.length * 60} px`)
  //   }
  // }, [data])

  const options = {
    title: indicator.unit === '' ? '' : `Jednotka: ${indicator.unit}`,
    chartArea: { width: '60%' },
    hAxis: { title: indicator.abbreviation }, // minValue: 0,
    vAxis: { title: 'Země' },
    legend: { position: 'none' },
  }

  return (
    <div style={{ margin: 'auto', width: '100%', height: '100%' }}>
      {data.length !== 0 ? (
        <Chart chartType="BarChart" width="100%" height="1000px" data={data} options={options} />
      ) : (
        <Stack>
          <CircularProgress sx={{ margin: 'auto' }} />
        </Stack>
      )}
    </div>
  )
}
