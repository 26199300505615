import { BrowserRouter } from 'react-router-dom'

import NavbarIndicators from './components/NavbarIndicators'
import NavbarTop from './components/NavbarTop'
import Footer from './components/Footer'
import AppRouter from './AppRouter'
import { AppProvider } from './AppContext'

// Example of logging:
// console.log(`categories = ${JSON.stringify(categories, null, 2)}`)
// console.log('categories = ', categories)

export default function App() {
  return (
    <BrowserRouter>
      <NavbarTop />
      <NavbarIndicators />
      <AppProvider>
        <AppRouter />
      </AppProvider>
      <Footer />
    </BrowserRouter>
  )
}
