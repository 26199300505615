import PropTypes from 'prop-types'
import { createContext, useContext, useMemo, useEffect, useState } from 'react'
import { fetchDataFromApi } from './common/utils'

const AppContext = createContext({
  indicatorId: undefined,
  setIndicatorId: undefined,
  chartId: undefined,
  setChartId: undefined,
  groupId: undefined,
  setGroupId: undefined,
  sourceId: undefined,
  setSourceId: undefined,
  yearFrom: undefined,
  setYearFrom: undefined,
  yearTo: undefined,
  setYearTo: undefined,
  indicator: undefined,
  chartOptions: undefined,
  groupOptions: undefined,
  sourceOptions: undefined,
  yearOptions: undefined,
})

export const useApp = () => {
  const context = useContext(AppContext)
  if (!context) {
    throw new Error('Parent must be wrapped inside AppProvider')
  }
  return context
}

export function AppProvider({ children }) {
  const [indicatorId, setIndicatorId] = useState()
  const [chartId, setChartId] = useState('tabulka')
  const [groupId, setGroupId] = useState('svet')
  const [sourceId, setSourceId] = useState()
  const [yearFrom, setYearFrom] = useState()
  const [yearTo, setYearTo] = useState()
  const [indicator, setIndicator] = useState()
  const [chartOptions, setChartOptions] = useState()
  const [groupOptions, setGroupOptions] = useState()
  const [sourceOptions, setSourceOptions] = useState()
  const [yearOptions, setYearOptions] = useState()

  // Setting up indicator
  useEffect(() => {
    if (indicatorId && sourceId) {
      fetchDataFromApi(`indicators/${sourceId}/${indicatorId}`, setIndicator)
    }
  }, [indicatorId, sourceId])

  // Setting up source ID
  useEffect(() => {
    if (indicator) {
      setSourceId(indicator.defaultSourceId)
    }
  }, [indicator])

  // Setting up options

  useEffect(() => fetchDataFromApi('options/charts', setChartOptions), [])

  useEffect(() => fetchDataFromApi('options/groups', setGroupOptions), [])

  useEffect(() => {
    if (indicatorId) {
      fetchDataFromApi(`options/sources/${indicatorId}`, setSourceOptions)
    }
  }, [indicatorId])

  useEffect(() => {
    if (indicatorId && groupId && sourceId) {
      fetchDataFromApi(`options/years/${sourceId}/${indicatorId}/${groupId}`, setYearOptions)
    }
  }, [indicatorId, groupId, sourceId])

  // Checking years
  useEffect(() => {
    if (yearOptions) {
      const yearOptionIds = yearOptions.map((elem) => elem.id)

      if (!yearOptionIds.includes(yearFrom)) {
        setYearFrom(yearOptionIds.slice(0)[0])
      }

      if (!yearOptionIds.includes(yearTo)) {
        setYearTo(yearOptionIds.slice(-1)[0])
      }
    }
  }, [yearOptions, yearFrom, yearTo])

  // Checking sources
  useEffect(() => {
    if (sourceOptions) {
      const sourceOptionsIds = sourceOptions.map((elem) => elem.id)

      if (!sourceOptionsIds.includes(sourceId)) {
        setYearFrom(sourceOptionsIds.slice(-1)[0])
      }
    }
  }, [sourceOptions, sourceId])

  const value = useMemo(
    () => ({
      indicatorId,
      setIndicatorId,
      chartId,
      setChartId,
      groupId,
      setGroupId,
      sourceId,
      setSourceId,
      yearFrom,
      setYearFrom,
      yearTo,
      setYearTo,
      indicator,
      chartOptions,
      groupOptions,
      sourceOptions,
      yearOptions,
    }),
    [
      indicatorId,
      setIndicatorId,
      chartId,
      setChartId,
      groupId,
      setGroupId,
      sourceId,
      setSourceId,
      yearFrom,
      setYearFrom,
      yearTo,
      setYearTo,
      indicator,
      chartOptions,
      groupOptions,
      sourceOptions,
      yearOptions,
    ],
  )

  return <AppContext.Provider value={value}>{children}</AppContext.Provider>
}

AppProvider.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
}
