import PropTypes from 'prop-types'
import { useEffect } from 'react'
import { Typography } from '@mui/material'

import Visualization from '../components/Visualization'
import SourceCard from '../components/SourceCard'
import ControlsSection from '../components/ControlsSection'
import { PageContainer, PageElem } from '../components/styled'
import { useApp } from '../AppContext'

export default function PageIndicator({ indicatorId, sourceId }) {
  const { indicator, setIndicatorId, setSourceId } = useApp()

  useEffect(() => setIndicatorId(indicatorId), [indicatorId, setIndicatorId])
  useEffect(() => setSourceId(sourceId), [sourceId, setSourceId])

  if (indicator) {
    return (
      <PageContainer>
        <PageElem>
          <Typography variant="h4" align="center">
            {indicator.name}
          </Typography>
        </PageElem>
        <PageElem>
          <Typography variant="body1" align="center">
            {indicator.description}
          </Typography>
        </PageElem>
        <PageElem>
          <ControlsSection />
        </PageElem>
        <PageElem>
          <Visualization />
        </PageElem>
        <PageElem>
          <SourceCard />
        </PageElem>
      </PageContainer>
    )
  }
  return null
}

PageIndicator.propTypes = {
  indicatorId: PropTypes.string.isRequired,
  sourceId: PropTypes.string.isRequired,
}
