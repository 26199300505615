export const apiUrl = 'https://api.jakjsmenatom.cz'
// export const apiUrl = 'http://localhost:8000'

// Colors
export const colorWhite = 'whitesmoke'
export const colorWhiteDark = 'silver'
export const colorBlue = '#0E6BCD'
export const colorBlueDark = '#345995'
export const colorBlueLight = '#6B86B2'
export const colorOrange = '#F18F00'
export const colorOrangeDark = '#d97500'
export const colorGreyDark = '#191923'
export const colorBlack = 'black'

// Sizes
export const maxWidthMiddle = '775px'
export const maxWidthSmall = '375px'

// Endpoints
export const endpointAbout = '/o_projektu'
export const endpointDonate = '/podporit'

// Enum chart type
export const ChartType = Object.freeze({
  barChart: 'sloupcovy_graf',
  lineChart: 'liniovy_graf',
  map: 'mapa',
  pieChart: 'kolacovy_graf',
  table: 'tabulka',
})

// Enum group ID
export const GroupId = Object.freeze({
  v4: 'v4',
  eu: 'eu',
  nato: 'nato',
  oecd: 'oecd',
  g20: 'g20',
  g8: 'g8',
  afrika: 'afrika',
  amerika: 'amerika',
  asie: 'asie',
  australie_a_oceanie: 'australie_a_oceanie',
  evropa: 'evropa',
  svet: 'svet',
})
