import { Typography } from '@mui/material'

import { PageContainer, PageElem } from '../components/styled'

export default function Page404() {
  return (
    <PageContainer>
      <PageElem>
        <Typography variant="h4" align="center">
          404
        </Typography>
      </PageElem>
      <PageElem>
        <Typography variant="h5" align="center">
          Tato stránka neexistuje
        </Typography>
      </PageElem>
    </PageContainer>
  )
}
