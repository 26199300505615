import axios from 'axios'

import { apiUrl, ChartType, GroupId } from './consts'

export function chartSupportsPeriod(chartId) {
  switch (chartId) {
    case ChartType.barChart:
    case ChartType.map:
    case ChartType.pieChart:
    case ChartType.table:
      return false
    case ChartType.lineChart:
      return true
    default:
      throw new Error('Error: unknown chart type')
  }
}

// See https://developers.google.com/chart/interactive/docs/gallery/geochart#data-format
export function getRegionCode(groupId) {
  switch (groupId) {
    case GroupId.v4:
    case GroupId.eu:
    case GroupId.evropa:
      return '150'
    case GroupId.afrika:
      return '002'
    case GroupId.amerika:
      return '019'
    case GroupId.asie:
      return '142'
    case GroupId.australie_a_oceanie:
      return '009'
    case GroupId.svet:
    case GroupId.nato:
    case GroupId.oecd:
    case GroupId.g8:
    case GroupId.g20:
      return undefined
    default:
      throw new Error('Error: unknown region ID')
  }
}

// Fetch & set data from the API
export async function fetchDataFromApi(apiEndpoint, setData) {
  if (apiEndpoint && setData) {
    axios
      .get(`${apiUrl}/${apiEndpoint}/`)
      .then((response) => setData(response.data))
      // eslint-disable-next-line no-console
      .catch((err) => console.log(`Error: ${err}`))
  }
}
