import { Chart } from 'react-google-charts'
import { useState, useEffect } from 'react'
import { CircularProgress, Stack } from '@mui/material'

import { ChartType } from '../common/consts'
import { fetchDataFromApi } from '../common/utils'
import { useApp } from '../AppContext'

export default function ChartLine() {
  const { indicator, indicatorId, groupId, sourceId, yearFrom, yearTo } = useApp()

  const [data, setData] = useState([])

  useEffect(() => {
    if (sourceId && indicatorId && groupId && yearFrom && yearTo) {
      setData([])
      fetchDataFromApi(
        `data/${ChartType.lineChart}/${sourceId}/${indicatorId}/${groupId}/${yearFrom}/${yearTo}`,
        setData,
      )
    }
  }, [sourceId, indicatorId, groupId, yearFrom, yearTo])

  const options = {
    title: indicator.unit === '' ? '' : `Jednotka: ${indicator.unit}`,
    curveType: 'function',
    legend: { position: 'bottom' },
    interpolateNulls: true,
  }

  return (
    <div style={{ margin: 'auto', width: '100%', height: '100%' }}>
      {data.length !== 0 ? (
        <Chart chartType="LineChart" width="100%" height="900px" data={data} options={options} />
      ) : (
        <Stack>
          <CircularProgress sx={{ margin: 'auto' }} />
        </Stack>
      )}
    </div>
  )
}
