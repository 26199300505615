import { createTheme } from '@mui/material/styles'

export const defaultTheme = createTheme({
  palette: {
    orange: {
      main: '#d97500',
      darker: '#F18F00',
    },
  },
})
