// https://mui.com/components/toggle-button/

import PropTypes from 'prop-types'
import { ToggleButton, ToggleButtonGroup } from '@mui/material'

const toggleButtonStyle = {
  width: '170px',
  height: '56px',
}

export default function ButtonGroup({ currentValue, setCurrentValue, options }) {
  if (currentValue && setCurrentValue && options) {
    return (
      <ToggleButtonGroup
        color="primary"
        value={currentValue}
        exclusive
        onChange={(event) => setCurrentValue(event.target.value)}
      >
        {options.map((option) => (
          <ToggleButton key={option.id} value={option.id} sx={{ ...toggleButtonStyle }}>
            {option.label}
          </ToggleButton>
        ))}
      </ToggleButtonGroup>
    )
  }
  return null
}

ButtonGroup.propTypes = {
  currentValue: PropTypes.string.isRequired,
  setCurrentValue: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
    }).isRequired,
  ).isRequired,
}
