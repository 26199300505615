import { useApp } from '../AppContext'
import { ChartType } from '../common/consts'
import BarChart from './ChartBar'
import LineChart from './ChartLine'
import Map from './ChartMap'
import PieChart from './ChartPie'
import Table from './ChartTable'

export default function Visualization() {
  const { chartId } = useApp()

  function getChart() {
    switch (chartId) {
      case ChartType.barChart:
        return <BarChart />
      case ChartType.lineChart:
        return <LineChart />
      case ChartType.map:
        return <Map />
      case ChartType.pieChart:
        return <PieChart />
      case ChartType.table:
        return <Table />
      default:
        throw new Error('Error: unknown chart type')
    }
  }

  return <>{getChart()}</>
}
