import styled from '@emotion/styled'
import { useEffect, useState } from 'react'
import { NavLink } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'

import { colorBlueDark, colorOrange, colorWhite } from '../common/consts'
import { fetchDataFromApi } from '../common/utils'

library.add(fas)

const Nav = styled.div`
  margin: auto;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background: ${colorBlueDark};
`

const Button = styled.button`
  background-color: ${colorBlueDark};
  color: ${colorWhite};
  padding: 16px;
  font-size: 16px;
  border: none;
  width: 175px;

  :hover div {
    display: block;
  }
  :hover {
    background: ${colorOrange};
  }
  :focus {
    outline: none;
  }
`

const Dropdown = styled.div`
  display: none;
  position: absolute;
  background-color: ${colorBlueDark};
  z-index: 10;
`

const Item = styled.a`
  color: ${colorWhite};
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  width: 250px;
  :hover {
    background: ${colorOrange};
    color: ${colorWhite};
  }
`

export default function NavbarIndicators() {
  const [categories, setCategories] = useState([])

  useEffect(() => fetchDataFromApi('categories', setCategories), [])

  return (
    <Nav>
      {categories ? (
        categories.map((category) => (
          <Button key={category.id}>
            <FontAwesomeIcon icon={category.icon} />
            &nbsp;&nbsp;{category.name}
            <Dropdown key={category.id} title={category.name}>
              {category.indicators.map((indicator) => (
                <Item as={NavLink} key={indicator.id} to={indicator.id}>
                  {indicator.abbreviation}
                </Item>
              ))}
            </Dropdown>
          </Button>
        ))
      ) : (
        // eslint-disable-next-line react/jsx-no-useless-fragment
        <></>
      )}
    </Nav>
  )
}
