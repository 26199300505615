import { Stack, Typography, List, ListItem } from '@mui/material'

import { PageContainer, PageElem } from '../components/styled'

export default function PageHome() {
  return (
    <PageContainer>
      <PageElem>
        <Typography variant="h4" align="center">
          Podpořit
        </Typography>
      </PageElem>
      <PageElem>
        <Stack direction="row" justifyContent="center" alignItems="center" spacing={5}>
          <List>
            <ListItem>Na této stránce budete moci brzy projekt podpořit.</ListItem>
          </List>
        </Stack>
      </PageElem>
    </PageContainer>
  )
}
