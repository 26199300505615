import { Chart } from 'react-google-charts'
import { useState, useEffect } from 'react'
import { CircularProgress, Stack } from '@mui/material'

import { ChartType } from '../common/consts'
import { fetchDataFromApi, getRegionCode } from '../common/utils'
import { useApp } from '../AppContext'

export default function CharMap() {
  const { indicator, indicatorId, groupId, sourceId, yearTo } = useApp()

  const [data, setData] = useState([])

  useEffect(() => {
    if (sourceId && indicatorId && groupId && yearTo) {
      fetchDataFromApi(`data/${ChartType.map}/${sourceId}/${indicatorId}/${groupId}/${yearTo}`, setData)
    }
  }, [sourceId, indicatorId, groupId, yearTo])

  const options = {
    title: indicator.unit === '' ? '' : `Jednotka: ${indicator.unit}`,
    region: getRegionCode(groupId),

    // Todo: formatter not working, probably bug
    // formatters: [
    //   {
    //     type: 'NumberFormat',
    //     column: 1,
    //     options: { groupingSymbol: ' ', decimalSymbol: ',', suffix: ' $' },
    //   },
    // ],

    // getChartWrapper: { type: (params) => Number(params.value).toLocaleString('cs-CZ') }

    // formatters: { type: (params) => Number(params.value).toLocaleString('cs-CZ') },

    // colorAxis: { colors: ['White', 'Black'] },
    // backgroundColor: '#81d4fa',
    // datalessRegionColor: '#F4F4F5',
    // defaultColor: '#F4F4F5',
  }

  return (
    <div style={{ margin: 'auto', width: '100%', height: '100%' }}>
      {data.length !== 0 ? (
        <Chart chartType="GeoChart" width="100%" height="100%" data={data} options={options} />
      ) : (
        <Stack>
          <CircularProgress sx={{ margin: 'auto' }} />
        </Stack>
      )}
    </div>
  )
}
