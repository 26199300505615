import styled from '@emotion/styled'
import { css } from '@emotion/react'
import { Container } from 'react-bootstrap'
import { colorWhite, colorWhiteDark } from '../common/consts'

export const globalStyles = css`
  html {
    height: 100%;
  }

  body {
    height: 100%;
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
      'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  h1, h2, h3, h4, h5, h6, p {
    margin: 0;
  }

  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
  }

  a {
    text-decoration: none;
    color: ${colorWhite};

    &:hover {
      color: ${colorWhiteDark};
    }
  }

  ul {
    list-style-type: none;
  }
`

export const PageContainer = styled(Container)`
  padding: 40px 0;
  margin: 0 auto;
`

export const PageElem = styled.div`
  padding: 40px 15px;
  margin: 0;
`
