import axios from 'axios'
import { useState, useEffect } from 'react'
import { Route, Routes } from 'react-router-dom'

import PageHome from './pages/Home'
import Page404 from './pages/404'
import PageDonate from './pages/Donate'
import PageAbout from './pages/About'
import PageIndicator from './pages/Indicator'
import { apiUrl, endpointAbout, endpointDonate } from './common/consts'

export default function AppRouter() {
  const [indicators, setIndicators] = useState([])

  useEffect(() => {
    async function fetchDataFromApi() {
      axios
        .get(`${apiUrl}/indicators/`)
        .then((response) => {
          setIndicators(
            response.data.map((indicator) => ({
              id: indicator.id,
              defaultSourceId: indicator.default_source_id,
            })),
          )
        })
        // eslint-disable-next-line no-console
        .catch((err) => console.log(`Error: ${err}`))
    }
    fetchDataFromApi()
  }, [])

  return (
    <Routes>
      <Route path="/" element={<PageHome />} />
      <Route path={endpointAbout} element={<PageAbout />} />
      <Route path={endpointDonate} element={<PageDonate />} />
      {indicators.map((indicator) => (
        <Route
          key={indicator.id}
          path={`/${indicator.id}`}
          element={<PageIndicator indicatorId={indicator.id} sourceId={indicator.defaultSourceId} />}
        />
      ))}
      <Route path="*" element={<Page404 />} />
    </Routes>
  )
}
