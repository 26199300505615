import styled from '@emotion/styled'
import ModalFooter from 'react-bootstrap/ModalFooter'
import { Container } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFacebook, faTwitter, faInstagram } from '@fortawesome/free-brands-svg-icons'

import { colorWhite, colorWhiteDark, colorGreyDark } from '../common/consts'

const Styles = styled(ModalFooter)`
  min-height: 90px;
  width: 100%;
  background-color: ${colorGreyDark};
  color: ${colorWhite};

  .social-media {
    display: flex;
    flex-direction: row;
  }
`

const Wrapper = styled(Container)`
  padding: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: auto;

  @media (max-width: 525px) {
    flex-direction: column;
  }
`

const Elem = styled.div`
  margin: 10px;
`

const Text = styled.p`
  text-align: center;
  margin: auto;
  font-size: 18px;
`

const Link = styled.a`
  color: ${colorWhite};
  margin: 5px;

  &:hover {
    color: ${colorWhiteDark};
  }
`

export default function Footer() {
  return (
    <Styles>
      <Wrapper>
        <Elem>
          <Text>
            © 2022 Vladimír Dušek
          </Text>
        </Elem>
        <Elem>
          <Text>
            Kontakt: <Link href="mailto:v.dusek96@gmail.com">v.dusek96@gmail.com</Link>
          </Text>
        </Elem>
        <Elem className="social-media">
          <Link href="https://facebook.com/jakjsmenatom">
            <FontAwesomeIcon icon={faFacebook} size="2x" />
          </Link>
          <Link href="https://twitter.com/jakjsmenatom">
            <i className="bi bi-facebook" />
            <FontAwesomeIcon icon={faTwitter} size="2x" />
          </Link>
          <Link href="https://instagram.com/jakjsmenatom">
            <FontAwesomeIcon icon={faInstagram} size="2x" />
          </Link>
        </Elem>
      </Wrapper>
    </Styles>
  )
}
