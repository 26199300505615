import Stack from '@mui/material/Stack'

import SelectBox from './SelectBox'
import ButtonGroup from './ButtonGroup'
import { useApp } from '../AppContext'
import { ChartType } from '../common/consts'

export default function ControlsSection() {
  const {
    chartId,
    groupId,
    sourceId,
    yearFrom,
    yearTo,
    chartOptions,
    groupOptions,
    sourceOptions,
    yearOptions,
    setChartId,
    setGroupId,
    setSourceId,
    setYearFrom,
    setYearTo,
  } = useApp()

  return (
    <Stack direction="column" spacing={5} justifyContent="center" alignItems="center">
      <Stack direction="row" spacing={5} justifyContent="center" alignItems="center">
        <SelectBox label="Skupina" currentValue={groupId} setCurrentValue={setGroupId} options={groupOptions} />
        <SelectBox label="Zdroj" currentValue={sourceId} setCurrentValue={setSourceId} options={sourceOptions} />
        {chartId !== ChartType.lineChart && (
          <SelectBox label="Rok" currentValue={yearTo} setCurrentValue={setYearTo} options={yearOptions} />
        )}
        {chartId === ChartType.lineChart && (
          <SelectBox label="Rok od" currentValue={yearFrom} setCurrentValue={setYearFrom} options={yearOptions} />
        )}
        {chartId === ChartType.lineChart && (
          <SelectBox label="Rok do" currentValue={yearTo} setCurrentValue={setYearTo} options={yearOptions} />
        )}
      </Stack>
      <ButtonGroup currentValue={chartId} setCurrentValue={setChartId} options={chartOptions} />
    </Stack>
  )
}
