import { Avatar, Stack, Typography, List, ListItem } from '@mui/material'

import { PageContainer, PageElem } from '../components/styled'

export default function PageHome() {
  return (
    <PageContainer>
      <PageElem>
        <Typography variant="h4" align="center">
          Jak jsme na tom?
        </Typography>
      </PageElem>
      <PageElem>
        <Stack direction="row" justifyContent="center" alignItems="center" spacing={5}>
          <List sx={{ maxWidth: 400 }}>
            <ListItem>
              Jak jsme na tom je webová aplikace, která staví nad otevřenými daty o zemích celého světa.
            </ListItem>
            <ListItem>Cílem je srozumitelně prezentovat data zejména v oblasti pokroku a kvality života.</ListItem>
            <ListItem>V současné době je jsou k dispozici data pro více než 50 indikátorů.</ListItem>
            <ListItem>Aplikace je &quot;čerstvá&quot; a proto omluvte případné nedostatky. :)</ListItem>
          </List>
          <Avatar alt="Avatar" src="logo.png" sx={{ width: 200, height: 200 }} />
        </Stack>
      </PageElem>
    </PageContainer>
  )
}
