import { useState, useEffect } from 'react'
import { DataGrid } from '@mui/x-data-grid'
import getUnicodeFlagIcon from 'country-flag-icons/unicode'

import { ChartType } from '../common/consts'
import { fetchDataFromApi } from '../common/utils'
import { useApp } from '../AppContext'

export default function ChartTable() {
  const { indicator, indicatorId, groupId, sourceId, yearTo } = useApp()

  const [data, setData] = useState([])

  useEffect(() => {
    if (sourceId && indicatorId && groupId && yearTo) {
      fetchDataFromApi(`data/${ChartType.table}/${sourceId}/${indicatorId}/${groupId}/${yearTo}`, setData)
    }
  }, [sourceId, indicatorId, groupId, yearTo])

  const columns = [
    {
      field: 'id',
      headerName: '#',
      sortable: false,
      width: 75,
    },
    {
      field: 'country',
      headerName: 'Země',
      sortable: false,
      width: 290,
      valueFormatter: (params) => `${getUnicodeFlagIcon(params.value.idAlp2)} ${params.value.name}`,
    },
    {
      field: 'value',
      headerName: 'Hodnota',
      sortable: true,
      width: 260,
      valueFormatter: (params) => `${Number(params.value).toLocaleString('cs-CZ')} ${indicator.unit}`,
    },
    {
      field: 'year',
      headerName: 'Rok',
      type: 'date',
      sortable: true,
      width: 115,
    },
  ]

  return (
    <div style={{ width: 750, margin: 'auto' }}>
      <DataGrid
        rows={data}
        columns={columns}
        pageSize={15}
        rowsPerPageOptions={[15]}
        disableSelectionOnClick
        disableColumnSelector
        autoHeight
        sx={{
          fontSize: 16,
          '&:focus': { outline: 0 },
          '&:focus-within': { outline: 0 },
        }}
      />
    </div>
  )
}
