import { Avatar, Button, Typography } from '@mui/material'
import Navbar from 'react-bootstrap/Navbar'
import Nav from 'react-bootstrap/Nav'
import styled from '@emotion/styled'
import { Container } from 'react-bootstrap'
import { Link } from 'react-router-dom'

import { colorGreyDark, endpointAbout, endpointDonate, maxWidthMiddle } from '../common/consts'

const Styles = styled.div`
  min-height: 90px;
  background-color: ${colorGreyDark};
  font-size: 18px;
  font-weight: 600;
`

const MyNavbar = styled(Navbar)`
  min-height: 90px;
  padding: 0;
  top: 0;

  @media (max-width: ${maxWidthMiddle}) {
    flex-direction: row;
    justify-content: space-evenly !important;
  }
`

const MyNav = styled(Nav)`
  width: 100%;

  @media (max-width: ${maxWidthMiddle}) {
    flex-direction: row;
    justify-content: space-evenly !important;
    margin-bottom: 10px;
  }
`

const MyNavLink = styled(Link)`
  margin: 18px;
  display: flex;
  align-items: center;

  @media (max-width: ${maxWidthMiddle}) {
    padding-left: 0;
    margin: 8px;
  }
`

const buttonStyles = {
  width: '120px',
  height: '40px',
}

export default function NavbarTop() {
  return (
    <Styles>
      <Container>
        <MyNavbar bg="custom" variant="dark" expand="md">
          <MyNavLink to="/">
            <Avatar alt="Avatar" src="logo.png" sx={{ marginRight: '20px' }} />
            <Typography variant="h4" align="center">
              Jak jsme na tom?
            </Typography>
          </MyNavLink>
          <Navbar.Collapse className="show">
            <MyNav className="mr-auto justify-content-end">
              <MyNavLink to={endpointAbout}>
                <Button variant="contained" color="orange" sx={{ ...buttonStyles }}>
                  O projektu
                </Button>
              </MyNavLink>
              <MyNavLink to={endpointDonate}>
                <Button variant="contained" color="orange" sx={{ ...buttonStyles }}>
                  Podpořit
                </Button>
              </MyNavLink>
            </MyNav>
          </Navbar.Collapse>
        </MyNavbar>
      </Container>
    </Styles>
  )
}
