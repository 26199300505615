import { useEffect, useState } from 'react'
import { Box, Card, CardContent, Typography, Link, Stack } from '@mui/material'

import { useApp } from '../AppContext'
import { fetchDataFromApi } from '../common/utils'

export default function SourceCard() {
  const { indicatorId, sourceId } = useApp()
  const [source, setSource] = useState()
  useEffect(() => fetchDataFromApi(`sources/${sourceId}/${indicatorId}`, setSource), [sourceId, indicatorId])

  if (source) {
    return (
      <Box sx={{ minWidth: 250, maxWidth: 500, margin: 'auto' }}>
        <Card variant="outlined">
          <CardContent>
            <Typography color="text.secondary" gutterBottom>
              Datový zdroj
            </Typography>
            <Typography variant="h5" component="div">
              {source.name}
            </Typography>
            <Typography sx={{ mb: 1.5 }} />
            <Typography variant="body">{source.description}</Typography>
            <Typography sx={{ mb: 1.5 }} />
            <Stack>
              <Link href={`https://${source.url}`} underline="none">
                {source.url}
              </Link>
              <Link href={`https://${source.indicatorUrl}`} underline="none">
                {source.indicatorUrl}
              </Link>
            </Stack>
          </CardContent>
        </Card>
      </Box>
    )
  }
  return null
}
